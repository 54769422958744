module.exports = [{
      plugin: require('/home/website_production/app/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-105363182-2","head":true,"anonymize":false},
    },{
      plugin: require('/home/website_production/app/node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"693591851020205"},
    },{
      plugin: require('/home/website_production/app/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1110},
    },{
      plugin: require('/home/website_production/app/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/website_production/app/node_modules/gatsby-plugin-emotion/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/website_production/app/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
